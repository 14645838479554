import async from "async"
import _ from 'lodash'
import Errors from "../../utils/Errors"
import {
    setFieldVisibility,
    changeFieldDisabled,
    setFieldListOptions,
    changeFieldProperty
} from '../../../apps/KpModule/actions'
import {generateFetchFieldListAction} from '../../../apps/KpModule/actions/api'
import {getDataListList} from "../../../apps/KpModule/selectors"

const numericalSourceFilter = {
    title: "Numerical sources",
    match: function(source) {
        return _.includes(
            ["type-float", "type-integer"],
            _.get(source, "dataType")
        );
    }
};

const getFieldsModuleForm = (element, fieldPaths, module) => {
    return fieldPaths.map(fieldPath => getFieldModuleForm(element, fieldPath, module))
}

export const getFieldModuleForm = (element, fieldPath, module) => {
    return module.viewMap.form.fields.find(
        field => field.path === element
    ).viewMap.form.fields.find(
        field => field.path === fieldPath
    )
}

const getFieldsModuleDt = (element, fieldPaths, module) => {
    return fieldPaths.map(fieldPath => (
        module.viewMap.form.fields.find(
            field => field.path === element
        ).viewMap.dt.fields.find(
            field => field.path === fieldPath
        )
    ))
}

function updateFieldsOptions({stream, store}) {
    const state = store.getState();

    const fieldsByDataLists = {
        "m-S-themeConfig.ThemeCondition_source": ["e_conditions.e_source", "e_conditions.e_source2", "e_mt.e_conditions.e_source", "e_mt.e_conditions.e_source2", "e_nb.e_conditions.e_source", "e_nb.e_conditions.e_source2"] ,

        "m-S-themeConfig.MtComponent_source": "e_mt.e_source",
        "m-S-themeConfig.MtComponent_lineTheme": "e_mt.e_lineTheme",

        "m-S-themeConfig.NbComponent_source": "e_nb.e_source",
        "m-S-themeConfig.NbComponent_lineTheme": "e_nb.e_lineTheme",
    }

    const dataLists = Object.keys(fieldsByDataLists)

    if (stream) {

        dataLists.forEach(dataList => {
            const options = getDataListList(
                state,
              dataList
            );

            const filteredOptions = options.filter(
                option => _.get(option, 'inputCorrespondenceByImportType.id', _.get(option, 'stream.id')) === stream.id
            )

            if( _.isString(fieldsByDataLists[dataList])) {
                store.dispatch(
                    setFieldListOptions(fieldsByDataLists[dataList], _.map(filteredOptions, "id"))
                );
            }
            if(_.isArray(fieldsByDataLists[dataList])) {
                fieldsByDataLists[dataList].forEach(fieldId => store.dispatch(
                    setFieldListOptions(fieldId, _.map(filteredOptions, "id"))
                ))
            }
        })
    } else {
        dataLists.forEach(
            dataList => store.dispatch(
                setFieldListOptions(fieldsByDataLists[dataList], [])
            )
        )
    }
}

export const dependencies = [
    {
        name: "ThemeCondition",
        type: "mongoInternal",
        fields: [
            {type: "ConditionType", nullable: true},
            {path: "source", type: "InputCorrespondence", uniqueWith: ["operator", "condValue", "source2"]},
            {path: "source2", type: "InputCorrespondence", nullable: true},
            {path: "operator", type: "CondOperator"},
            "condValue",
            "condValues"
        ]
    },
    {
        name: "MtComponent",
        type: "mongoInternal",
        fields: [
            {path: "source", type: "InputCorrespondence", nullable: true},
            {path: "negative", type: "boolean", default: false},
            {path: "conditions", type: "ThemeCondition", link: "OTM"},
            {path: "applyExchangeRate", type: "boolean", default: false, nullable: true},
            {path: "lineTheme", type: "ThemeConfig", nullable: true},
            {path: "lineThemeCondOperator", type: "CondOperator", nullable: true},
            {path: "lineThemeCondValue", nullable: true},
            {path: "lineThemeCondValues", nullable: true}
        ]
    },
    {
        name: "NbComponent",
        type: "mongoInternal",
        fields: [
            {type: "NumberConditionType", nullable: true},
            {path: "source", type: "InputCorrespondence", nullable: true},
            {path: 'operation', type: "Operation", nullable: true},
            {path: "negative", type: "boolean", default: false},
            {path: "conditions", type: "ThemeCondition", link: "OTM"},
            {path: "constantValue", type: "boolean", default: false},
            {path: "lineTheme", type: "ThemeConfig", nullable: true},
            {path: "lineThemeCondOperator", type: "CondOperator", nullable: true},
            {path: "lineThemeCondValue", nullable: true},
            {path: "lineThemeCondValues", nullable: true}
        ]
    }
]

export const entity = {
    name: "ThemeConfig",
    facets: ["translatedField"],
    fields: [
        {
            path: "code",
            ps: {object: ["mongoFieldName"]},
            notEmpty: true,
            index: true,
            unique: true
        },
        "name",
        {path: "stream", type:"InputCorrespondenceByImportType"},
        {path: "groupedTheme", type: "boolean"},
        {path: "dormant", type: "boolean"},
        {path: "conditions", type: "ThemeCondition", link: "OTM"},
        {path: "mt", type: "MtComponent", link: "OTM"},
        {path: "nb", type: "NbComponent", link: "OTM"},
        {
            path: "themeKeyName",
            fieldPath: ["code"],
            f: function() { return `theme-${this.code}`; }
        },
        {
            path: "mtKeyName",
            fieldPath: ["code"],
            f: function() { return `theme-${this.code}.Mt`; }
        },
        {
            path: "nbKeyName",
            fieldPath: ["code"],
            f: function() { return `theme-${this.code}.Nb`; }
        },
        {
            path: "completeCode",
            fieldPath: ["themeKeyName"],
            f: function() { return this.themeKeyName; }
        },
        /*
        {
            path: "fullName",
            fieldPath: ["name", "importType.tKey"],
            f: function() { return `${this.importType.tKey}-${this.name}`; }
        },
         */
        {
            path: "allInputCorrespondencesIds",
            f: function() {
                const conditionsinputCorrespondences = _(this.conditions)
                    .filter(condition => condition)
                    .map(condition => condition.source)
                    .map(o => o.toString())
                    .value();

                const mtinputCorrespondences = _(this.mt)
                    .map(mtValue => [mtValue.source, ...mtValue.conditions.map(rule => rule.source)])
                    .flatten()
                    .filter(o => o)
                    .map(o => o.toString())
                    .value();

                const nbinputCorrespondences = _(this.nb)
                    .map(nbValue => [nbValue.source, ...nbValue.conditions.map(rule => rule.source)])
                    .flatten()
                    .filter(o => o)
                    .map(o => o.toString())
                    .value();

                return _([conditionsinputCorrespondences, mtinputCorrespondences, nbinputCorrespondences])
                    .flatten()
                    .uniq()
                    .value();
            }
        },
    ],
    filters: [
        {
            name: 'notEqualTo',
            isDefault: false,
            query: function(context) {
                const themeConfigId = _.get(context, "data.themeConfig.id")
                if(themeConfigId) return {_id: {$ne: new global.ObjectID(themeConfigId)}}
                return {}
            }
        }
    ],
    nameCannotContainHyphens: (newObject, oldObject, context, callback) => {
        const containsHyphens = newObject.name.indexOf('-') !== -1
        if(containsHyphens) return callback(new Errors.ValidationError('nameCannotContainHyphens'))
        return callback()
    },
    prerequisitesShouldBeUnique: (newObject, oldObject, context, callback) => {
        const hasDuplicates = newObject.conditions.some((conditionA, index, self) =>
                index !== self.findIndex((conditionB) => (
                    conditionA.source.id === conditionB.source.id
                    && conditionA.source2?.id === conditionB.source2?.id
                    && conditionA.operator.id === conditionB.operator.id
                    && conditionA.condValue === conditionB.condValue
                ))
        )
        if(hasDuplicates) return callback(new Errors.ValidationError('conditionsFieldHasDuplicates'))
        return callback()
    },
    mustHaveAtLeastOneRule: (newObject, oldObject, context, callback) => {

        if(newObject.mt.length === 0 && newObject.nb.length === 0 ) return callback(new Errors.ValidationError('themeConfigMustHaveAtLeastOneRule'))
        return callback()
    },
    cannotModifyUsedTheme: (newObject, oldObject, context, callback) => {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C' || newObject.code === oldObject.code) return callback()
        global.app.S.ElemDataLine.collection
            .findOne(
                {[`theme-${oldObject.code}`]: { $exists: true}},
                (e, theme) => {
                    if (e) return callback(e)
                    if (!!theme) return callback(new Errors.ValidationError('cannotChangeUsedThemeCode'))
                    return callback()
                })
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.nameCannotContainHyphens(newObject, oldObject, context, callback),
            callback => this.prerequisitesShouldBeUnique(newObject, oldObject, context, callback),
            callback => this.mustHaveAtLeastOneRule(newObject, oldObject, context, callback),
            callback => this.cannotModifyUsedTheme(newObject, oldObject, context, callback)
        ], callback)
    },
    ps: {
        delete: [
            {
                $$v: function (object, callback) {
                    async.parallel({
                        alertConfiguration: callback =>  global.app.S.AlertConfiguration.collection.findOne({$or: [
                                {'alertFields.themeJoin': new global.ObjectID(object.id)},
                                {'alertFields.denominatorTheme': new global.ObjectID(object.id)},
                            ]}, callback),
                        scoringConfig: callback =>  global.app.S.ScoringConfig.collection.findOne({$or: [
                                {'scoringFields.numeratorTheme': new global.ObjectID(object.id)},
                                {'scoringFields.denominatorTheme': new global.ObjectID(object.id)},
                            ]}, callback),
                    }, (e, results) => {
                        if (e) return callback(e)
                        if(results.alertConfiguration) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteThemeUsedInAlertConfiguration', {themeCode: object.code})))
                        if(results.scoringConfig) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteThemeUsedInScoringConfiguration', {themeCode: object.code})))
                        global.db.collection('slp.elemData').updateMany(
                            {group: new global.ObjectID(this.options.context?.group.id)},
                            {$unset: {[`theme-${object.code}`]: ''}},
                            callback
                        )
                    })
                }
            }
        ]
    }
}

export const module_ = {
    object: "ThemeConfig",
    tKey: "mTitle_ThemeConfig",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "code",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            "code",
            {path: "name", type: "translatedText"},
            {path: "stream"},
            "groupedTheme"
        ],
        form: {
            fields : [
                {
                    path: "stream",
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            updateFieldsOptions({stream: newValue, store})
                        }
                    }
                },
                {path: "code",},
                {path: "name", type: "textarea", required: true},
                {
                    path: "groupedTheme",
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            const state = store.getState()
                            const prerequisitesField = module.viewMap.form.fields.find(
                                field => field.path === 'conditions'
                            )

                            const isTicketTheme = newValue

                            if(isTicketTheme) prerequisitesField.setValue([])
                            store.dispatch(setFieldVisibility(prerequisitesField.id, !isTicketTheme))

                            const mtDtThemeFields = getFieldsModuleDt(
                                'mt',
                                ['source'],
                                module
                            )
                            const mtFormThemeFields = getFieldsModuleForm(
                                'mt',
                                ['source', 'applyExchangeRate', 'conditions'],
                                module
                            )
                            _.concat(mtDtThemeFields, mtFormThemeFields).forEach(field => {
                                if(isTicketTheme) {
                                    if(field.setValue) {
                                        if(field.path === 'source') field.setValue(null)
                                        if(field.path === 'applyExchangeRate') field.setValue(false)
                                        if(field.path === 'conditions') field.setValue([])
                                    }
                                }
                                store.dispatch(setFieldVisibility(field.id, !isTicketTheme))
                            })

                            const mtDtIndicatorFields = getFieldsModuleDt(
                                'mt',
                                ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue', 'lineThemeCondValues'],
                                module
                            )

                            const mtFormIndicatorFields = getFieldsModuleForm(
                                'mt',
                                ['lineTheme', 'lineThemeCondOperator'],
                                module)

                            const nbDtIndicatorFields = getFieldsModuleDt(
                                'nb',
                                ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue', 'lineThemeCondValues'],
                                module
                            )

                            _.concat(mtDtIndicatorFields, mtFormIndicatorFields, nbDtIndicatorFields).forEach(field => {
                                if(!isTicketTheme && field.setValue) field.setValue(null)
                                store.dispatch(setFieldVisibility(field.id, isTicketTheme))
                            })

                            store.dispatch(setFieldListOptions(
                                "e_nb.e_numberConditionType",
                                    !!newValue
                                        ? ["checkExistence", "applyDataCondition", "applyThemeCondition"]
                                        : ["checkExistence", "applyDataCondition"]
                                )
                            )

                            const numberConditionsTypeField = getFieldModuleForm('nb', 'numberConditionType', module)

                            if(newValue !== oldValue) {
                                numberConditionsTypeField.setValue(null)
                                const nbFormIndicatorFields = getFieldsModuleForm(
                                    'nb',
                                    ['source', 'operation', 'conditions', 'lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue', 'lineThemeCondValues'],
                                    module)
                                nbFormIndicatorFields.forEach(field => {
                                    if(field.path === 'conditions') field.setValue([])
                                    else field.setValue(null)
                                })
                            }
                        }
                    }
                },
                {
                    path: "conditions",
                    tKey: "filteringConditions",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "source", tKey: "cashierFileId"},
                            {path: "operator", translate: true},
                            {path: "source2", tKey: "cashierFileId2"},
                            {path: "condValue", tKey: "value"},
                            {path: "condValues", tKey: "values"}
                        ],
                        form: [
                            {
                                path: "conditionType",
                                type: "toggle",
                                sortList: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const operatorField = getFieldModuleForm("conditions", "operator", module)
                                        const valueField = getFieldModuleForm('conditions', 'condValue', module)
                                        const valuesField = getFieldModuleForm('conditions', 'condValues', module)
                                        const compareToField = getFieldModuleForm('conditions', 'source2', module)

                                        const operator = operatorField.getValue()
                                        const showValuesField = !!operator && ['000000000000000000000018', '000000000000000000000019'].includes(operator.id)
                                        const isApplyConditionType = !!newValue && newValue.id === "applyCondition"
                                        const isApplyComparisonType = !!newValue && newValue.id === "applyComparison"

                                        store.dispatch(
                                            setFieldVisibility(
                                                valueField.id,
                                                isApplyConditionType && !showValuesField
                                            )
                                        )
                                        store.dispatch(
                                            setFieldVisibility(
                                                valuesField.id,
                                                isApplyConditionType && showValuesField
                                            )
                                        )

                                        if(isApplyConditionType) {
                                            store.dispatch(changeFieldProperty('e_conditions.e_source', 'tKey', 'cashierFileId'))
                                            compareToField.setValue(null)
                                        }

                                        if(isApplyComparisonType) {
                                            store.dispatch(changeFieldProperty('e_conditions.e_source', 'tKey', 'cashierFileId1'))
                                            valueField.setValue(null)
                                            valuesField.setValue(null)

                                            if(showValuesField) {
                                                operatorField.setValue(null)
                                            }
                                        }

                                        const defaultFields = ["source", "operator"]


                                        defaultFields.forEach(path => {
                                            store.dispatch(setFieldVisibility(`e_conditions.e_${path}`, !!newValue))
                                        })
                                        store.dispatch(setFieldVisibility(`e_conditions.e_source2`, isApplyComparisonType))

                                        if(!!newValue) {
                                            const state = store.getState()
                                            const options = getDataListList(
                                                state,
                                                'm-S-themeConfig.ThemeCondition_operator'
                                            );
                                            const filteredOptions = options.filter(option => {
                                                if(newValue.id === "applyCondition") return true
                                                return !["000000000000000000000018", "000000000000000000000019"].includes(option.id)
                                            })

                                            store.dispatch(setFieldListOptions("e_conditions.e_operator", _.map(filteredOptions, "id")))
                                        }
                                    }
                                }
                            },
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                            {
                                path: "operator",
                                translate: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const valueField = getFieldModuleForm('conditions', 'condValue', module)
                                        const valuesField = getFieldModuleForm('conditions', 'condValues', module)
                                        const conditionTypeField = getFieldModuleForm('conditions', 'conditionType', module)

                                        const conditionType = conditionTypeField.getValue()

                                        const isApplyConditionType = conditionType?.id === "applyCondition"

                                        const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                        store.dispatch(
                                            setFieldVisibility(
                                                valuesField.id,
                                                isApplyConditionType && showValuesField
                                            )
                                        )
                                        store.dispatch(
                                            setFieldVisibility(
                                                valueField.id,
                                                isApplyConditionType && !showValuesField
                                            )
                                        )

                                        if(!showValuesField) {
                                            valuesField.setValue(null)
                                        }

                                        if(showValuesField) {
                                            valueField.setValue(null)
                                        }
                                    }
                                },
                                hidden: true
                            },
                            {path: "condValue", tKey: "value", hidden: true},
                            {path: "condValues", tKey: "values", type: 'creatableTags', hidden: true},
                            {path: "source2", tKey: "cashierFileId2", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                        ]
                    }
                },

                {
                    path: "mt",
                    tKey: "amount",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "source", tKey: "cashierFileId"},
                            {path: "lineTheme", tKey: "theme", display: "tName"},
                            {path: "lineThemeCondOperator", tKey: 'condition', translate: true},
                            {path: "lineThemeCondValue", tKey: "value"},
                            {path: "lineThemeCondValues", tKey: "values"},
                            {path: "negative", tKey: "changeSign"},
                        ],
                        form: [
                            {path: "negative", tKey: "changeSign"},
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'], filters: [numericalSourceFilter]},
                            "applyExchangeRate",
                            {
                                path: "conditions",
                                tKey: "filteringConditions",
                                removable: true,
                                viewMap: {
                                    dt: [
                                        {path: "source", tKey: "cashierFileId"},
                                        {path: "operator", translate: true},
                                        {path: "source2", tKey: "cashierFileId2"},
                                        {path: "condValue", tKey: "value"}
                                    ],
                                    form: [
                                        {
                                            path: "conditionType",
                                            type: "toggle",
                                            sortList: false,
                                            subscriptions: {
                                                onChange: (newValue, oldValue, {module, store}) => {
                                                    const mtField = module.viewMap.form.fields.find(field => field.path === 'mt')
                                                    const operatorField = getFieldModuleForm("conditions", "operator", mtField)
                                                    const valueField = getFieldModuleForm("conditions", "condValue", mtField)
                                                    const valuesField = getFieldModuleForm('conditions', 'condValues', mtField)
                                                    const compareToField = getFieldModuleForm('conditions', 'source2', mtField)
                                                    const operator = operatorField.getValue()
                                                    const showValuesField = !!operator && ['000000000000000000000018', '000000000000000000000019'].includes(operator.id)
                                                    const isApplyConditionType = !!newValue && newValue.id === "applyCondition"
                                                    const isApplyComparisonType = !!newValue && newValue.id === "applyComparison"

                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valueField.id,
                                                            isApplyConditionType && !showValuesField
                                                        )
                                                    )
                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valuesField.id,
                                                            isApplyConditionType && showValuesField
                                                        )
                                                    )

                                                    if(isApplyConditionType) {
                                                        store.dispatch(changeFieldProperty('e_mt.e_conditions.e_source', 'tKey', 'cashierFileId'))
                                                        compareToField.setValue(null)
                                                    }
                                                    if(isApplyComparisonType) {
                                                        store.dispatch(changeFieldProperty('e_mt.e_conditions.e_source', 'tKey', 'cashierFileId1'))
                                                        valueField.setValue(null)
                                                        valuesField.setValue(null)

                                                        if(showValuesField) {
                                                            operatorField.setValue(null)
                                                        }
                                                    }

                                                    const defaultFields = ["source", "operator"]


                                                    defaultFields.forEach(path => {
                                                        store.dispatch(setFieldVisibility(`e_mt.e_conditions.e_${path}`, !!newValue))
                                                    })
                                                    store.dispatch(setFieldVisibility(`e_mt.e_conditions.e_source2`, isApplyComparisonType))

                                                    if(!!newValue) {
                                                        const state = store.getState()
                                                        const options = getDataListList(
                                                            state,
                                                            'm-S-themeConfig.ThemeCondition_operator'
                                                        );
                                                        const filteredOptions = options.filter(option => {
                                                            if(newValue.id === "applyCondition") return true
                                                            return !["000000000000000000000018", "000000000000000000000019"].includes(option.id)
                                                        })

                                                        store.dispatch(setFieldListOptions("e_mt.e_conditions.e_operator", _.map(filteredOptions, "id")))
                                                    }
                                                }
                                            }
                                        },
                                        {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                                        {
                                            path: "operator",
                                            translate: true,
                                            subscriptions: {
                                                onChange: (newValue, oldValue, {module, store}) => {
                                                    const mtField = module.viewMap.form.fields.find(field => field.path === 'mt')
                                                    const valueField = getFieldModuleForm('conditions', 'condValue', mtField)
                                                    const valuesField = getFieldModuleForm('conditions', 'condValues', mtField)
                                                    const conditionTypeField = getFieldModuleForm('conditions', 'conditionType', mtField)

                                                    const conditionType = conditionTypeField.getValue()

                                                    const isApplyConditionType = conditionType?.id === "applyCondition"

                                                    const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valuesField.id,
                                                            isApplyConditionType && showValuesField
                                                        )
                                                    )
                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valueField.id,
                                                            isApplyConditionType && !showValuesField
                                                        )
                                                    )

                                                    if(!showValuesField) {
                                                        valuesField.setValue(null)
                                                    }

                                                    if(showValuesField) {
                                                        valueField.setValue(null)
                                                    }
                                                }
                                            },
                                            hidden: true
                                        },
                                        {path: "source2", tKey: "cashierFileId2", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                                        {path: "condValue", tKey: "value", hidden: true},
                                        {path: "condValues", tKey: "values", type: 'creatableTags', hidden: true}
                                    ]
                                }
                            },
                            {path: "lineTheme", tKey: "theme", display: "tName", fieldPath: ['id', "code", 'tName', 'stream.id'], filters: ['notEqualTo'], mField: {reloadList: true}},
                            {
                                path: "lineThemeCondOperator",
                                tKey: 'condition',
                                translate: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const valueField = getFieldModuleForm('mt', 'lineThemeCondValue', module)
                                        const valuesField = getFieldModuleForm('mt', 'lineThemeCondValues', module)


                                        const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                        store.dispatch(setFieldVisibility(valuesField.id, newValue && showValuesField))
                                        store.dispatch(setFieldVisibility(valueField.id, newValue && !showValuesField))

                                        if(!showValuesField) {
                                            valuesField.setValue(null)
                                        }

                                        if(showValuesField) {
                                            valueField.setValue(null)
                                        }
                                    }
                                }
                            },
                            {path: "lineThemeCondValue", tKey: "value"},
                            {path: "lineThemeCondValues", tKey: "values", type: 'creatableTags'},
                        ]
                    }
                },

                {
                    path: "nb",
                    tKey: "number",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "numberConditionType", tKey: "type"},
                            {path: "source", tKey: "cashierFileId"},
                            {path: "lineTheme", tKey: "theme", display: "tName"},
                            {path: "lineThemeCondOperator", tKey: "condition", translate: true},
                            {path: "lineThemeCondValue", tKey: "value"},
                            {path: "lineThemeCondValues", tKey: "values"},
                            {path: "negative", tKey: "changeSign"},
                        ],
                        form: [
                            {path: "negative", tKey: "changeSign"},
                            {
                                path: "numberConditionType",
                                type: "toggle",
                                sortList: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const groupedField = module.viewMap.form.fields.find(field => field.path === 'groupedTheme')
                                        const nbField = module.viewMap.form.fields.find(field => field.path === 'nb')
                                        const operationField = nbField.viewMap.form.fields.find(field => field.path === 'operation')

                                        const themeIsGrouped = groupedField.getValue()
                                        const isDataCondition = newValue?.id === "applyDataCondition"
                                        const isThemeCondition = newValue?.id === "applyThemeCondition"

                                        operationField.setValue(
                                            themeIsGrouped && isDataCondition ? {id: "000000000000000000000901" } : null
                                        )
                                        store.dispatch(setFieldVisibility("e_nb.e_operation", themeIsGrouped && isDataCondition))
                                        store.dispatch(changeFieldDisabled("e_nb.e_operation", themeIsGrouped && isDataCondition))

                                        const dataConditionFields = ["source", "conditions"]
                                        const themeConditionFields = ["lineTheme", "lineThemeCondOperator"]

                                        dataConditionFields.forEach(path => {
                                            store.dispatch(setFieldVisibility(`e_nb.e_${path}`, isDataCondition))
                                        })

                                        themeConditionFields.forEach(path => {
                                            store.dispatch(setFieldVisibility(`e_nb.e_${path}`, isThemeCondition))
                                        })

                                        if(!isThemeCondition) {
                                            store.dispatch(setFieldVisibility("e_nb.e_lineThemeCondValue", false))
                                            store.dispatch(setFieldVisibility("e_nb.e_lineThemeCondValues", false))
                                        }


                                    }

                                }
                            },
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                            //filters: [numericalSourceFilter]
                            {path: "operation", hidden: true},
                            {
                                path: "conditions",
                                removable: true,
                                hidden: true,
                                viewMap: {
                                    dt: [
                                        {path: "source", tKey: "cashierFileId"},
                                        {path: "operator", translate: true},
                                        {path: "source2", tKey: "cashierFileId2"},
                                        {path: "condValue", tKey: "value"},
                                        {path: "condValues", tKey: "values"}
                                    ],
                                    form: [
                                        {
                                            path: "conditionType",
                                            type: "toggle",
                                            sortList: false,
                                            subscriptions: {
                                                onChange: (newValue, oldValue, {module, store}) => {
                                                    const nbField = module.viewMap.form.fields.find(field => field.path === 'nb')
                                                    const operatorField = getFieldModuleForm("conditions", "operator", nbField)
                                                    const valueField = getFieldModuleForm("conditions", "condValue", nbField)
                                                    const valuesField = getFieldModuleForm('conditions', 'condValues', nbField)
                                                    const compareToField = getFieldModuleForm('conditions', 'source2', nbField)
                                                    const operator = operatorField.getValue()
                                                    const showValuesField = !!operator && ['000000000000000000000018', '000000000000000000000019'].includes(operator.id)
                                                    const isApplyConditionType = !!newValue && newValue.id === "applyCondition"
                                                    const isApplyComparisonType = !!newValue && newValue.id === "applyComparison"

                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valueField.id,
                                                            isApplyConditionType && !showValuesField
                                                        )
                                                    )
                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valuesField.id,
                                                            isApplyConditionType && showValuesField
                                                        )
                                                    )

                                                    if(isApplyConditionType) {
                                                        store.dispatch(changeFieldProperty('e_nb.e_conditions.e_source', 'tKey', 'cashierFileId'))
                                                        compareToField.setValue(null)
                                                    }
                                                    if(isApplyComparisonType) {
                                                        store.dispatch(changeFieldProperty('e_nb.e_conditions.e_source', 'tKey', 'cashierFileId1'))
                                                        valueField.setValue(null)
                                                        valuesField.setValue(null)

                                                        if(showValuesField) {
                                                            operatorField.setValue(null)
                                                        }
                                                    }

                                                    const defaultFields = ["source", "operator"]


                                                    defaultFields.forEach(path => {
                                                        store.dispatch(setFieldVisibility(`e_nb.e_conditions.e_${path}`, !!newValue))
                                                    })
                                                    store.dispatch(setFieldVisibility(`e_nb.e_conditions.e_source2`, isApplyComparisonType))

                                                    if(!!newValue) {
                                                        const state = store.getState()
                                                        const options = getDataListList(
                                                            state,
                                                            'm-S-themeConfig.ThemeCondition_operator'
                                                        );
                                                        const filteredOptions = options.filter(option => {
                                                            if(newValue.id === "applyCondition") return true
                                                            return !["000000000000000000000018", "000000000000000000000019"].includes(option.id)
                                                        })

                                                        store.dispatch(setFieldListOptions("e_nb.e_conditions.e_operator", _.map(filteredOptions, "id")))
                                                    }
                                                }
                                            }
                                        },
                                        {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                                        {
                                            path: "operator",
                                            translate: true,
                                            hidden: true,
                                            subscriptions: {
                                                onChange: (newValue, oldValue, {module, store}) => {
                                                    const mtField = module.viewMap.form.fields.find(field => field.path === 'nb')
                                                    const valueField = getFieldModuleForm('conditions', 'condValue', mtField)
                                                    const valuesField = getFieldModuleForm('conditions', 'condValues', mtField)
                                                    const conditionTypeField = getFieldModuleForm('conditions', 'conditionType', mtField)

                                                    const conditionType = conditionTypeField.getValue()

                                                    const isApplyConditionType = conditionType?.id === "applyCondition"

                                                    const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valuesField.id,
                                                            isApplyConditionType && showValuesField
                                                        )
                                                    )
                                                    store.dispatch(
                                                        setFieldVisibility(
                                                            valueField.id,
                                                            isApplyConditionType && !showValuesField
                                                        )
                                                    )

                                                    if(!showValuesField) {
                                                        valuesField.setValue(null)
                                                    }

                                                    if(showValuesField) {
                                                        valueField.setValue(null)
                                                    }
                                                }
                                            }
                                        },
                                        {path: "source2", tKey: "cashierFileId2", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'], hidden: true},
                                        {path: "condValue", tKey: "value", hidden: true},
                                        {path: "condValues", tKey: "values", type: 'creatableTags', hidden: true}
                                    ]
                                }
                            },
                            {path: "lineTheme", tKey: "theme", display: "tName", fieldPath: ['id', 'code', 'tName', 'stream.id'], filters: ['notEqualTo'], mField: {reloadList: true}, hidden: true},
                            {
                                path: "lineThemeCondOperator",
                                tKey: "condition",
                                translate: true,
                                hidden: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const valueField = getFieldModuleForm('nb', 'lineThemeCondValue', module)
                                        const valuesField = getFieldModuleForm('nb', 'lineThemeCondValues', module)


                                        const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                        store.dispatch(setFieldVisibility(valuesField.id, newValue && showValuesField))
                                        store.dispatch(setFieldVisibility(valueField.id, newValue && !showValuesField))

                                        if(!showValuesField) {
                                            valuesField.setValue(null)
                                        }

                                        if(showValuesField) {
                                            valueField.setValue(null)
                                        }
                                    }
                                }
                            },
                            {path: "lineThemeCondValue", tKey: "value", hidden: true},
                            {path: "lineThemeCondValues", tKey: "values", type: 'creatableTags', hidden: true,},
                        ]
                    }
                },
                { path: 'dormant', tKey: "suspend"},
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const themeConfigId = _.get(state, "form.editObject.values.id")
                const isThemeGrouped = _.get(state, "form.editObject.values.groupedTheme")
                const dataLists = ["m-S-themeConfig.MtComponent_lineTheme", "m-S-themeConfig.NbComponent_lineTheme"]

                dataLists.forEach(dataList => store.dispatch(
                    generateFetchFieldListAction(
                        dataList,
                        store.getState,
                        'form',
                        {
                            data: {themeConfig: {id: themeConfigId}}
                        }
                    )
                ))
                const stream = state.edit.object.data.stream

                updateFieldsOptions({stream, store})

                store.dispatch(setFieldListOptions(
                        "e_nb.e_numberConditionType",
                        !!isThemeGrouped
                            ? ["checkExistence", "applyDataCondition", "applyThemeCondition"]
                            : ["checkExistence", "applyDataCondition"]
                    )
                )
            }
        }
    },
    filters: [
        {
            path: 'stream',
            display: 'name',
            object: 'InputCorrespondenceByImportType',
            client: true,
            clearable: false,
            query: (context) => {
                const streamId = _.get(context, 'data.stream.id')
                return streamId
                    ? {stream: global.ObjectID(streamId)}
                    : {}
            }
        }
    ]
}
